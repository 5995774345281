.spiro-dropdown-menu {
  height: 30px;
  margin: 0;
  position: relative;
  display: inline-block;
}

.spiro-dropdown-menu-container {
  position: absolute;
  z-index: 999;
  top: 0px;
  width: 100%;
  display: flex;
  pointer-events: none;

  &.shadowed {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
}

.spiro-dropdown-menu-field {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 5px;
  background-color: $white;
  min-width: 140px;
  font-size: 14px;
  color: $white;
  cursor: pointer;
  white-space: nowrap;
  &:focus {
    outline: none;
  }

  & > span {
    margin-left: 5px;
  }
}

.spiro-dropdown-menu-field-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: $white;
  position: relative;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
}

.spiro-dropdown-menu-field-placeholder {
  display: inline-block;
}
