.wizard-container {
  color: $smalt-blue;

  &.spiro-card {
    margin-bottom: 70px;
  }

  .spiro-card__header {
    border-bottom: 1px solid $iron;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    background-color: white;
    z-index: 5;

    .spiro-card__title {
      color: $smalt-blue;
      position: absolute;
      left: 20px;
      top: 18px;
    }

    & :last-child {
      display: inline-block;
      margin-bottom: 2px;
    }
  }

  .spiro-card__section {
    background-color: $aqua-haze3;
    border: none;
    padding: 20px 15px;
  }

  .spiro-card__section,
  .spiro-card__body {
    font-size: 14px;
    color: $smalt-blue;
    min-height: calc(100vh - 230px);
  }

  .spiro-card__body {
    background-color: $aqua-haze3;

    &:not(:last-child) {
      border-bottom: 1px $iron solid;
    }
  }

  .spiro-card__footer {
    background-color: white;
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 100px;
    right: 0;
    z-index: 1;
    box-shadow: 0px 2px 16px rgba(83, 117, 140, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.05);

    button {
      width: 120px;

      &:first-child {
        margin-right: 5px;
      }
    }
  }
}

.import-wizard__step-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.import-wizard__step-wrapper {
  min-width: 360px;
}

.import-wizard__step-number {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 1rem 0;
  margin-top: 5px;
  border-radius: 50%;
  background-color: $casper;
  color: $white;
  font-size: 16px;
  line-height: 14px;
  text-align: center;

  &.active {
    background-color: $flamingo;
  }

  &.check-sign {
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: center;
    margin-bottom: -7px;
    position: relative;
    top: 4px;
  }
}

.import-wizard__header {
  border-bottom: 1px $iron solid;
  text-align: center;
  margin: 0 -20px;
  padding: 20px;
  padding-top: 15px;
}

.import-wizard__header-content {
  .import-wizard__step-container:not(:first-child) {
    margin-left: 50px;
  }
}

.import-wizard__body-title {
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
}

.import-wizard__body {
  display: flex;
  justify-content: center;
}

.import-wizard__step-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  width: 25%;
  padding: 20px;
  text-align: center;

  &:not(:first-child) {
    margin-left: 20px;
  }

  .import-wizard__step-card-title {
    font-size: 20px;
    margin-top: 10px;
  }

  .import-wizard__step-card-text {
    font-size: 14px;
    margin-top: 22px;
    margin-bottom: 26px;
  }

  .import-wizard__step-image {
    display: inline-block;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-size: cover;
  }
}

.import-delete-modal {
  text-align: center;
}
