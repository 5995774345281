$primary-white: #f4f6f8;
$primary-purple: #371071;
$primary-orange: #f06223;
$light-purple: #5d43cf;

$white: #ffffff;
$aquaHaze: #eef2f5;
$alabaster: #f7f7f7;
$gray: #8c8c8c; // backgrop
$smalt-blue: #53758c; // sidebar background
$eastern-blue: #20aa9e; // save/submit modal button
$valencia: #d1533f; // cancel modal button
$vivid-tangerine: #ff8a78;
$iron: #dadee0; //some borders
$solitude: #e9f4ff; // filter menu item hover
$cornflower-blue: #6b67ff; //companies
$medium-purple: #9b5cda; //entities view
$aqua-haze2: #eef2f5; //voice log background
$aqua-haze3: #f6f8fa;
$aqua-haze4: #edf2f5;
$dove-gray: #666666; //chat message text
$flamingo: #ee6324;
$botticelli: #d9e6ed;
$nepal: #8eabbb; //settings buttons
$gothic: #7594a7; //checkbox border
$cosmos: #ffd1d1;
$gallery: #eeeeee;
$fruit-salad: #4caf50;
$orange-peel: #ff9800;
$electric-violet: #7c4dff;
$roman-coffee: #795548;
$san-marino: #3f51b5;
$cerulean: #03a9f4;
$smalt-blue1: #5a758a;

// Common colors
$ghostWhite: #f9fbfc;
$whiteSmoke: #ecf2f5;
$mineShaft: #3d3d3d;
$ecruWhite: #f7f5ea;

// Opportunities
$dodger-blue: #309bff;
$solitude: #e9f4ff;
$hawkesBlue: #e3f0fc;
$endeavour: #0055a3; //priority view icon

// Contacts table
$turquoise: #33d6c8;
$aliceBlue: #e9faf9;
$lightCyan: #e4f7f6;

// Timeline table
$casper: #a9c4d1;
$catskillWhite: #f5f8fa;

// Reminders table
$salmon: #ff7878;
$lavenderBlush: #fff0f0;
$linen: #f9edee;

// campaigns
$ronchi: #efc656;

$spiro-orange: #f16522;
$spiro-tr-hover: #fdefe9;
$spiro-white: #ffffff;
$spiro-grey: #a6a8ab;
$spiro-grey-2: #d0d2d3;
$spiro-grey-3: #bbbdbf;
$spiro-grey-4: #828383;
$spiro-grey-5: #f2f2f2;
$spiro-grey-6: #808184;
$spiro-grey-7: #999999;
$spiro-dark-grey: #4c4d4d;
$spiro-background: #e6e7e8;

$stage-proposing: #eba485;
$stage-qualifying: #ed8354;
$stage-closing: #f06223;
$stage-lost: #b3b4b6;

/* Colors from extension */

$color-black-1: #2b2b2b;
$color-black-2: #363636;
$color-black-3: #4c4d4d; //$spiro-dark-grey
$color-black-4: #6a6b6b;

$color-gray-1: #828384; //$spiro-grey-6
$color-gray-2: #cccccc;
$color-gray-3: #e3e2e1;
$color-gray-4: #969696;
$color-gray-5: #a6a8ab;
$color-gray-6: #d0d2d3;
$color-gray-7: #e5e5e5;
$color-gray-8: #b3b3b3;

$color-white-1: #ffffff; //$spiro-white
$color-white-2: #ededed;
$color-white-3: #f2f2f2; //$spiro-grey-5
$color-white-4: #fafafa;

$color-orange-1: #f06223; //$stage-closing
$color-orange-2: #d1561f;
$color-orange-3: #b56947;
$color-orange-4: #f0b397;
$color-orange-office-1: #ed6325;
$color-orange-office-2: #d35029;
$color-orange-hubspot-1: #f7751f;
$color-orange-hubspot-2: #ea6921; //TODO change darker hubspot color

$color-red-1: #c41823;
$color-red-google-1: #db4d46;
$color-red-google-2: #c63d2d;

$color-blue-salesforce-1: #109ad7;
$color-blue-salesforce-2: #1590ba;
$color-blue-outlook-1: #1072b4;
$color-blue-outlook-2: #116493;
$color-blue-light: #64b6f7;

//Import

$aqua-haze: #f1f6f7;
$alizarin-crimson: #ee2424;

$pomegranate: #f44336;
$pomegranate-1: #f02323;
$slate-blue: #6d56d4;
$mercury: #e6e6e6;
$azure: #2196f3;
$cod-gray: #1a1a1a;
$persian-indigo: #471592;
$selago: #e9defa;
$light-grayish-blue: #dfd9f5;
$pale-purple: #f2efff;
$dark-grayish-blue: #808088;
$dark-gray: #4d4d4d;
$spiro-black: #000000;
$spiro-success: #3b873e;
$mine-shaft: #3a3a3a;
$dusty-gray: #949494;
$spiro-charcoal: #414141;
$pigment-green: #19ab4f;
$biloba-flower: #bd9bf1;
$trolley-grey: #808080;
$bridesmaid: #feeceb;
$conifer: #add646;
$caper: #dbedad;
$wild-sand: #f5f5f5;

$harp: #e8f4e9;
$serenade: #fff5e5;
$indochine: #c77700;
$lochmara: #0b79d0;
$solitude: #e9f5fe;
$sadle-brown: #663d00;
$bright-dusk: #efecfa;
$venetian-red: #e31b0c;
$eerie-black: #1d1c1b;
$spiro-yellow-transparent: #ffea8a3d;
$desert-storm: #e7e0ec;
$dark-blue: #0000ff;
$mine-shaft: #333333;
$jaffa: #f17239;
$soft-green: #7bc67e;
$warm-coral: #f88078;
$darg-grey: #2d2d2d;
$lavender-blue: #7d69d9;

.search-icon circle {
  fill: #53758c;
}

.search-icon path {
  fill: #53758c;
}
