.import-mapping-screen-msg-wrapper {
  border: 1px solid rgba(128, 128, 128, 0.24);
  border-radius: 8px;
  margin-bottom: 24px;

  .import-mapping-screen-subheading {
    padding-left: 24px;

    .import-mapping-screen-star {
      color: red;
    }
  }
}

.import-mapping-screen-msg {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;

  .import-mapping-screen-icon {
    color: $light-purple;
    margin-right: 16px;
  }
}

.import-mapping-screen__required {
  width: 50%;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 12px;
}

.import-mapping-table {
  border: 1px solid rgba(128, 128, 128, 0.24);
  border-radius: 8px;

  table {
    width: 100%;

    th {
      border-bottom: 1px solid rgba(128, 128, 128, 0.24);
      min-height: 60px;
      text-align: start;
      padding: 22px 16px;
      font-weight: 500;
    }

    th:first-child {
      border-right: 1px solid rgba(128, 128, 128, 0.24);
      width: 30%;
    }

    tbody tr td {
      border-bottom: 1px solid rgba(128, 128, 128, 0.24);
      vertical-align: middle;

      .import-mapping-form__required {
        color: red;
      }

      .import-mapping-form__select-wrapper {
        width: 45%;
      }
    }

    tr td:first-child {
      border-right: 1px solid rgba(128, 128, 128, 0.24);
      padding-left: 16px;
      font-weight: 500;
    }

    // tr td:last-child {
    //   border-bottom: none;
    // }
  }
}

.import-mapping-screen-footer {
  display: flex;
  justify-content: flex-end;
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 236px;
  right: 0;
  z-index: 1;
  border-top: 1px solid $mercury;
  padding: 30px 24px;

  button:first-child {
    margin-right: 16px;
  }
}
