.import-validation-screen-wrapper {
  border: 1px solid rgba(128, 128, 128, 0.24);
  border-radius: 8px;

  .import-validation-screen-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 250px);

    .import-validation-screen__msg {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      padding-top: 24px;
    }
  }

  .import-validation-screen-footer-md {
    display: flex;
    justify-content: flex-end;
    background-color: $white;
    position: fixed;
    bottom: 0;
    left: 253px;
    right: 0;
    z-index: 1;
    border-top: 1px solid $mercury;
    padding: 30px 24px;
    transition: left .3s linear;

    button:first-child {
      margin-right: 16px;
    }
  }

  .import-validation-screen-footer-lg {
    @extend .import-validation-screen-footer-md;
    left: 73px;
  }
}
