@import "month-view";
@import "week-view";
@import "calendar-sidebar";

.spiro-calendar-view {
  height: calc(100vh - 64px);
  margin-left: -20px;
  width: calc(100% + 44px);
  display: grid;
  grid-template-columns: 236px minmax(0, 1fr);
  grid-template-rows: minmax(86px, max-content) 1fr;
  grid-template-areas:
    'header header'
    'sidebar content';

  .spiro-calendar-body {
    grid-area: content;
    padding: 0;
    overflow-y: auto;
    background-color: $white;
  }
  .spiro-calendar-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
 