.import-menu-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(128, 128, 128, 0.24);
  cursor: pointer;
  outline: none;

  &__icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin: 0 20px 0 40px;
    color: $spiro-grey-7;

    &:hover {
      color: $light-purple;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    margin: 16px;
    margin-left: 0;
    display: inline-block;
  }

  &:hover {
    background-color: rgba(128, 128, 128, 0.08);
  }
}
