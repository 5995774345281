.spiro-data-grid__cell a {
  color: $smalt-blue;
}

.spiro-data-grid__editable-cell {
  position: relative;

  &:hover {
    .spiro-data-grid__editable-cell__state-switch {
      display: block;
    }
  }
}

.spiro-data-grid__editable-cell__state-switch {
  display: none;
  position: absolute;
  right: 15px;
  top: 10px;
  padding: 2px 5px!important;
}

.spiro-data-grid__cell .input-grid-cell__input-field--currency {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}