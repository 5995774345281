.table-header-container {
  background: $white;
  position: relative;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 16px;
  width: 1038px;

  .data-grid-search {
    margin-right: 33px;
  }
}

.export__text {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 26px;
  color: $eastern-blue;
  padding-left: 8px;
  position: relative;
  top: -4px;
}

.table-header-filters {
  font-weight: normal;

  .spiro-dropdown-list-item {
    padding-top: 2px;
  }

  .filter-form__add-button span:last-child {
    top: -3px;
  }

  .spiro-checkbox.sm:checked + label:after {
    top: 6px;
  }

  .spiro-select__list-item {
    height: 40px;
  }

  .spiro-select__list-item a,
  .spiro-select__list-item div {
    padding-top: 10px;
  }

  .spiro-dropdown-menu-field {
    color: $smalt-blue;
    padding-bottom: 0;
  }
  .spiro-dropdown-menu-field-icon {
    background-color: $smalt-blue;
  }
  .spiro-select.spiro-select--primary.spiro-select-native {
    display: flex;
    align-items: center;
  }

  .spiro-dropdown-list-footer {
    padding: 0 0 0 5px;

    svg {
      fill: $smalt-blue;
    }

    span {
      position: relative;
      top: 4px;
    }
  }

  .spiro-select__input span {
    &:first-child {
      position: relative;
      bottom: 3px;
    }
  }

  .spiro-dropdown-list-item {
    padding-top: 2px;
  }

  .filter-form__add-button span:last-child {
    top: -3px;
  }

  .spiro-checkbox.sm:checked + label:after {
    top: 6px;
  }

  .spiro-select__list-item {
    height: 40px;
  }

  .spiro-select__list-item a,
  .spiro-select__list-item div {
    padding-top: 10px;
  }
}

@media only screen and (min-width: 1400px) {
  .table-header-container {
    width: 1247px;
  }
}
