.import-data-empty {
  margin: 50px auto;
  padding: 60px 0;
  width: 768px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.161);
  text-align: center;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #53758c;
}
