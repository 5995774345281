.update-import-data {
  border: 1px solid rgba(128, 128, 128, 0.24);
  border-radius: 8px;
  padding: 24px;

  &__header {
    display: flex;
  }

  &__icon {
    color: $light-purple;
    margin-right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
  }

  &__message {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 16px;
    line-height: 26px;
  }
}

.update-import-data-footer {
  display: flex;
  justify-content: flex-end;
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 236px;
  right: 0;
  z-index: 1;
  border-top: 1px solid $mercury;
  padding: 30px 24px;

  button:first-child {
    margin-right: 16px;
  }
}
