.menu-container {
  border-radius: 8px;
  border: 1px solid rgba(128, 128, 128, 0.24);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  height: 48px;

  &:hover {
    cursor: pointer;
  }
}

.import-menu-card {
  &__icon {
    color: $light-purple;
    padding: 0px 28px 0px 20px;
  }

  &__title {
    font-style: normal;
  }
}
