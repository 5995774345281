.spiro-data-grid {
  position: relative;

  .spiro-data-grid__column {
    width: 150px;
    text-align: inherit;
  }

  .spiro-data-grid__cell {
    width: 150px;
    padding: 4px 0px 3px 20px;
  }
}

.spiro-data-grid__fixed-header {
  overflow-x: scroll;
}

.spiro-data-grid__horizontal-scroller {
  min-height: 70px;
  overflow-x: scroll;
  overflow-y: visible;
  padding-bottom: 1px;
  height: auto;
}

.spiro-data-grid__vertical-scroller {
  position: relative;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1px;
  max-height: 1050px;
}

@media (max-height: 960px) {
  .spiro-data-grid__vertical-scroller {
    max-height: 975px; // 15 rows
  }
}

@media (max-height: 720px) {
  .spiro-data-grid__vertical-scroller {
    max-height: 650px; // 10 rows
  }
}

@media (max-height: 480px) {
  .spiro-data-grid__vertical-scroller {
    max-height: 325px; // 5 rows
  }
}

.spiro-data-grid__additional_horizontal-scroller {
  overflow-x: scroll;
  & > div {
    height: 1px;
  }
  // &.fix {
  //   height: 5px;
  //   overflow-y: hidden;
  // }
}

.spiro-data-grid.few-columns {
  table {
    width: 100%;
    table-layout: auto;
  }
  .spiro-data-grid__fixed-header,
  .spiro-data-grid__horizontal-scroller {
    .spiro-data-grid__column {
      min-width: 200px;
      width: auto;
    }
    .spiro-data-grid__cell {
      min-width: 200px;
      overflow: hidden;
      width: auto;
    }
  }

  .spiro-data-grid__fixed-header {
    overflow-x: hidden;
  }
}
