@mixin round-icon-frame($icon-size) {
  width: $icon-size;
  height: $icon-size;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $icon-size;
}

@mixin clear-left-nth-child($cycles, $offset) {
  &:nth-child(#{$cycles}n + #{$offset}) {
    clear: left;
  }
}

@mixin bp-xsmall {
  @media only screen and (max-width: $xs-max-width) {
    @content;
  }
}

@mixin bp-small {
  @media only screen and (min-width: $sm-min-width) and (max-width: $sm-max-width) {
    @content;
  }
}

@mixin bp-medium {
  @media only screen and (min-width: $md-min-width) and (max-width: $md-max-width) {
    @content;
  }
}

@mixin bp-large {
  @media only screen and (min-width: $lg-min-width) {
    @content;
  }
}

@mixin bp-ipad {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    @content;
  }
}

@mixin bp-ipad-portrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

@mixin bp-ipad-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

// iPad 3 & 4 in portrait and landscape mode
@mixin bp-ipad-retina {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin box($width, $height) {
  width: $width;
  height: $height;
}
