.spiro-calendar-view {
  .month-view-container {
    width: 100%;
    height: 100%;
  }
  .month-view-grid {
    display: grid;
    grid-auto-rows: 138px;
    grid-template-columns: repeat(7, minmax(100px, 1fr));

    .day {
      padding: 8px 5px;
      border-left: 1px solid $mercury;
      border-bottom: 1px solid $mercury;

      div[role='link'] {
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      .calendar-item {
        height: 20px;
        font-size: 10px;
        line-height: 20px;
        margin-top: 3px;
        cursor: pointer;
        position: relative;
        border-radius: 4px;

        &:focus {
          outline: none;
        }

        &.draggable {
          cursor: move;
        }
      }

      .show-more-link {
        position: relative;
        text-align: right;
        font-size: 12px;
        color: $mineShaft;
        margin-top: 5px;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
    }

    .day-label {
      font-size: 10px;
      display: block;
      color: $spiro-black;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      margin: 8px 16px;

      &.different-month {
        color: $spiro-grey-7;
      }

      &.today {
        color: $white;
        background-color: $light-purple;
        border-radius: 50%;
      }
    }
  }

  .month-view-header {
    display: grid;
    grid-template-columns: repeat(7, minmax(100px, 1fr));
    position: sticky;
    z-index: 100;
    top: 0;
    > div {
      flex: 1;
      border-left: 1px solid $mercury;
      font-size: 10px;
      padding-top: 21px;
      padding-left: 16px;
      padding-bottom: 15px;
      color: $dark-gray;
      text-transform: uppercase;
      border-bottom: 1px solid $mercury;
      background: $white;
      z-index: 1000;
    }
  }
}

.calendar-item-description {
  display: inline-block;
  width: 99%;
  vertical-align: super;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;

  &.with-icon {
    width: calc(100% - 20px);
  }
}

.calendar-item-icon {
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: top;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
}
