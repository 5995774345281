.create-new-import {
  width: 1038px;
  margin: auto;
  text-align: right;

  a {
    background-color: $eastern-blue;
    color: $white;
    font-style: normal;
    font-size: 14px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1400px) {
  .create-new-import {
    width: 1247px;
  }
}

.import-history-wrapper {
  padding-bottom: 30px;
}

.download-field {
  font-size: 14px;
  font-weight: 500;
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    text-decoration: underline;

    &:disabled {
      cursor: not-allowed;
      text-decoration: none;
    }
  }
}
