.import-choose-entity {
  width: 506px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin: 0;
    line-height: 26px;
    color: $smalt-blue;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 22px;
  }
  .import-choose-entity-card {
    background: $white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.161);
    border-radius: 4px;
    width: 506px;
  }
}

.import-choose-entity-select {
  width: 345px;
  margin-top: 36px;
}

.import-choose-entity-footer {
  display: flex;
  justify-content: flex-end;
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 236px;
  right: 0;
  z-index: 1;
  border-top: 1px solid $mercury;
  padding: 30px 24px;

  button:first-child {
    margin-right: 16px;
  }
}
