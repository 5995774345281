.spiro-calendar-sidebar {
  background-color: $white;
  padding: 0px 16px;
  flex-basis: 236px;
  .spiro-calendar {
    padding: 0;
    border: none;
    position: static;
    .header {
      color: $smalt-blue;
      font-family: 'Roboto', 'system';
      padding-right: 5px;
    }

    .day.today span {
      background-color: $light-purple;
    }

    .week-grid {
      margin-left: -5px;
    }
  }

  & > .spiro-checkbox-container {
    margin-left: 10px;
    margin-top: 24px;
    label span {
      color: $smalt-blue;
    }

    .spiro-checkbox:checked + label:before {
      background-color: $smalt-blue;
    }
  }
}

.calendar-user-select {
  margin-top: 52px;
}

.calendar-type-select {
  margin-top: 52px;
}
