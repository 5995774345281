.spiro-data-grid__cell {
  color: #000;
  vertical-align: middle;
}

.spiro-data-grid__cell:first-child {
  background-color: $white;
}

.spiro-data-grid__cell .input-grid-cell__display,
.spiro-data-grid__cell .input-grid-cell__display--clickable {
  height: 34px;
  line-height: 20px;
}

.spiro-data-grid__cell .spiro-multiselect > input {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
