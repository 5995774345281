.import-menu-content {
  position: relative;
  width: 506px;
  left: 50%;
  transform: translateX(-50%);
}
.import-menu-help {
  display: flex;
  justify-content: center;
  padding: 40px 0;

  .import-menu-help__link {
    color: $eastern-blue;
    padding: 0 4px;
    position: relative;
    font-weight: normal;
    letter-spacing: 0.03em;
  }
}
