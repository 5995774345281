.spiro-data-grid__column:first-child {
  width: 200px;
}

.spiro-data-grid__column__content {
  padding: 10px 0px 20px 20px;
  font-weight: 500;
  font-size: 14px;
  background-color: $white;
  line-height: 16px;
  // width: 150px;
  text-align: inherit;

  .spiro-data-grid__column__sort-icon {
    display: none;
  }
}

.spiro-data-grid__column__sort-icon {
  color: $smalt-blue;
}

.spiro-data-grid__column--sortable {
  cursor: pointer;

  &:hover {
    background: $color-white-4;
  }

  .spiro-data-grid__column__content {
    text-align: inherit;
  }

  .spiro-data-grid__column__sort-icon {
    display: inline-block;
  }
}

.spiro-data-grid__column--active {
  .spiro-data-grid__column__sort-icon {
    color: $color-black-1;
  }
}
