.spiro-calendar-view {
  .week-view-container {
    width: 100%;
    height: 645px;
    position: relative;
    overflow: hidden;
    border-left: 1px solid $mercury;

    .month-view-grid .day-label {
      display: none;
    }

    .week-view-header-container {
      position: relative;
      width: 100%;
      overflow-y: auto;
      padding-right: 17px;
      box-sizing: content-box;
    }

    .week-view-header-border {
      position: absolute;
      width: calc(100% - 60px);
      top: 80px;
      right: 0;
      border-bottom: 1px solid $mercury;

      &.bottom {
        position: absolute;
        bottom: 0px;
      }
    }

    .month-view-container {
      width: calc(100% - 60px);
      height: initial;
      margin-left: 60px;
    }

    .month-view-grid {
      border-top: none;
      grid-auto-rows: 85px;
      .day {
        min-height: 85px;
        border-bottom: none;
      }
    }

    &.day-mode{
      .month-view-grid {
        grid-auto-rows: auto;
        grid-template-columns: 1fr;
      }
    }
  }

  .week-view-body {
    display: flex;
    position: relative;
    height: calc(100% - 165px);
    overflow-y: auto;
  }

  .week-view-grid {
    display: flex;
    flex-grow: 1;
    grid-auto-rows: 60px;
    grid-template-columns: repeat(7, minmax(100px, 1fr));
    border-top: 1px solid $mercury;
    height: 1440px;

    .timeslot {
      position: absolute;
      display: flex;
      width: 100%;
      padding-right: 6px;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .calendar-item {
      overflow: hidden;
      flex: 1;
      border-radius: 3px;
      border: 1px solid white;
      position: relative;
      &:focus {
        outline: none;
      }

      .calendar-item-description {
        position: relative;
        display: block;

        &.time {
          white-space: nowrap;
        }
      }

      &.reminder-item {
        .calendar-item-description {
          display: inline-block;
          width: 99%;
          vertical-align: text-bottom;
        }
      }
    }

    .day-column {
      border-left: 1px solid $mercury;
      flex: 1;
      position: relative;
    }

    .day-column-cointainer {
      position: relative;
      width: 100%;
      height: 100%;
    }

    
  }

  .week-view-grid-labels {
    flex-basis: 60px;
    > div {
      height: 60px;

      &:first-child {
        > div {
          display: none;
        }
      }
      
      div {
        font-size: 10px;
        color: $spiro-grey-7;
        display: inline-block;
        position: relative;
        padding-right: 5px;
        width: calc(100% - 10px);
        text-align: center;
        top: -12px;
      }

      &::after {
        content: '';
        border-bottom: $mercury 1px solid;
        position: absolute;
        width: calc(100% - 50px);
        margin-top: -1px;
        z-index: 3;
        pointer-events: none;
      }
    }
  }

  .week-view-header {
    display: flex;
    border-bottom: 1px solid $mercury;
      z-index: 100;

    .header-placeholder {
      flex-basis: 60px;
      position: relative;

      span {
        text-align: center;
        color: $spiro-grey-7;
        font-size: 10px;
        position: absolute;
        bottom: -15px;
        left: 10px;
      }
    }
  }

  .week-view-header-labels {
    display: flex;
    flex-grow: 1;
     > div {
      flex: 1;
      border-left: 1px solid $mercury;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 16px;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      > div {
        
        &:nth-child(1) {
          font-size: 10px;
          text-transform: uppercase;
          color: $dark-gray;
        }
        &:nth-child(2) {
          line-height: 24px;
          text-align: center;
          font-size: 14px;
          width: 24px;
          height: 24px;
          color: $spiro-black;
          border-radius: 50%;
        }
      }
    }
  }

  .active-day {
    background-color: $light-purple;
    color: $white !important;
  }
}